var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("จัดการรายการสั่งซื้อ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersTransaction,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.firstName + " " + item.user.lastName))])]}},{key:"item.deliveryStatus",fn:function(ref){
var item = ref.item;
return [(item.deliveryStatus == "WAITING")?_c('v-chip',[_vm._v("รอจัดส่ง")]):(item.deliveryStatus == "SENT")?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("ส่งแล้ว")]):_vm._e()]}},{key:"item.isDelivery",fn:function(ref){
var item = ref.item;
return [(item.isDelivery == true)?_c('span',[_vm._v("จัดส่งตามที่อยู่")]):(item.isDelivery == false)?_c('span',{attrs:{"color":"green","dark":""}},[_vm._v("เก็บในตู้เซพ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.viewTransaction(item)}}},[_vm._v("mdi-eye")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteTransaction(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }