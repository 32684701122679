<template>
  <div>
    <ManageTransaction />
  </div>
</template>
<script>
import ManageTransaction from "@/components/Transaction/ManageTransaction";
export default {
  components: {
    ManageTransaction,
  },
  created() {
  },
};
</script>