
<template>
  <v-card class="pa-8">
    <h3>ข้อมูลการสั่งซื้อ</h3>
    <v-row class="text-end"> </v-row>
    <v-row>
      <v-col cols="6">
        ชื่อจริง
        <v-text-field v-model="firstName" outlined dense></v-text-field>
      </v-col>
      <v-col cols="6">
        นามสกุล
        <v-text-field v-model="lastName" outlined dense></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        อีเมล
        <v-text-field v-model="email" outlined dense></v-text-field>
      </v-col>
      <v-col cols="6">
        หมายเลขโทรศัพท์
        <v-text-field v-model="telNumber" outlined dense></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <div v-if="isDelivery">
        <v-col cols="6">
          การจัดส่ง
          <v-text-field v-model="isDelivery" outlined dense></v-text-field>
        </v-col>
        <v-col cols="6">
          ที่อยู่
          <v-text-field v-model="address" outlined dense></v-text-field>
        </v-col>

        <v-col cols="6">
          ตำบล/เขต
          <v-text-field v-model="subDistrict" outlined dense></v-text-field>
        </v-col>
        <v-col cols="6">
          อำเภอ/แขวง
          <v-text-field v-model="district" outlined dense></v-text-field>
        </v-col>
        <v-col cols="6">
          จังหวัด
          <v-text-field v-model="province" outlined dense></v-text-field>
        </v-col>
        <v-col cols="6">
          รหัสไปรษณีย์
          <v-text-field v-model="postcode" outlined dense></v-text-field>
        </v-col>
      </div>
    </v-row>
    <v-row v-for="(i, index) in lotteryTransaction" :key="index">
      <v-card
        class="pa-4"
        width="400px"
        color="rgb(114 212 249)"
        style="
          background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
          color: white;
          font-weight: 800;
        "
      >
        <v-row>
          <v-col cols="12">
            หมายเลขล็อตเตอรี่ : {{ i.lottery.lotteryNo }}
          </v-col>
          <v-col cols="12"> วันที่ : {{ i.lottery.lotteryDate }} </v-col>
          <v-col cols="12"> ราคา : {{ i.lottery.price }} </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="6">
        สถานะการจ่ายเงิน
        <v-text-field v-model="status" outlined dense></v-text-field>
      </v-col>
      <v-col cols="6">
        สถานะการจัดส่ง
        <v-select
          dense
          solo
          v-model="deliveryStatus"
          :items="deliveryStatusitems"
        >
        </v-select>
      </v-col>
    </v-row>
    <br />
    <br />
    <br />
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()" color="primary">แก้ไข</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      telNumber: "",
      activeFlag: true,

      deliveryStatus: "",
      deliveryStatusitems: [
        { text: "รอการจัดส่ง", value: "WAITING" },
        { text: "ส่งแล้ว", value: "SENT" },
      ],
      isDelivery: "",
      address: "",
      createdAt: "",
      district: "",
      postcode: "",
      province: "",
      subDistrict: "",
      transactionId: "",
      updatedAt: "",
      lotteryTransaction: "",
      status: "",
      totalPrice: "",
      updatedAt: "",
    };
  },
  created() {
    var Transactiondata = JSON.parse(
      Decode.decode(localStorage.getItem("Transactiondata"))
    );

    console.log("Transactiondata", Transactiondata);
    this.id = Transactiondata.id;
    this.firstName = Transactiondata.user.firstName;
    this.lastName = Transactiondata.user.lastName;
    this.email = Transactiondata.user.email;
    this.telNumber = Transactiondata.user.telNumber;

    this.isDelivery = Transactiondata.isDelivery;
    this.address = Transactiondata.deliveryAddress.address;
    // createdAt;
    this.district = Transactiondata.deliveryAddress.district;
    this.postcode = Transactiondata.deliveryAddress.postcode;
    this.province = Transactiondata.deliveryAddress.province;
    this.subDistrict = Transactiondata.deliveryAddress.subDistrict;
    this.lotteryTransaction = Transactiondata.lotteryTransaction;
    // updatedAt;
    // lotteryTransaction;
    this.status = Transactiondata.status;
    this.deliveryStatus = Transactiondata.deliveryStatus;
    // totalPrice;
    // updatedAt;
  },
  methods: {
    cancel() {
      this.$router.push("ManageUser");
    },
    async submit() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("ReadyLotAdmin"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const data = {
        // id: this.id,
        // firstName: this.firstName,
        // lastName: this.lastName,
        // email: this.email,
        // telNumber: this.telNumber,
        deliveryStatus: this.deliveryStatus,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/transaction/update/` + this.id,
        data,
        auth
      );
      console.log("createUser", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขข้อมูลสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("ManageTransaction");
      } else {
        this.$swal.fire({
          icon: "error",
          text: `แก้ไขข้อมูลไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>