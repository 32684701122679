<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>จัดการรายการสั่งซื้อ</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มประเภทสินค้า</v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersTransaction"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.fullname`]="{ item }">
              <span>{{ item.user.firstName + " " + item.user.lastName }}</span>
            </template>
            <template v-slot:[`item.deliveryStatus`]="{ item }">
              <v-chip v-if="item.deliveryStatus == `WAITING`">รอจัดส่ง</v-chip>
              <v-chip
                v-else-if="item.deliveryStatus == `SENT`"
                color="green"
                dark
                >ส่งแล้ว</v-chip
              >
            </template>
            <template v-slot:[`item.isDelivery`]="{ item }">
              <span v-if="item.isDelivery == true">จัดส่งตามที่อยู่</span>
              <span v-else-if="item.isDelivery == false" color="green" dark
                >เก็บในตู้เซพ</span
              >
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="viewTransaction(item)">mdi-eye</v-icon>
                <!-- <v-icon @click="UpdateTransaction(item)" class="mx-2"
                  >mdi-pencil</v-icon
                > -->
                <v-icon @click="DeleteTransaction(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      headersTransaction: [
        { text: "ลำดับ", value: "count", align: "center" },
        {
          text: "ชื่อผู้สั่ง",
          value: "fullname",
          align: "center",
        },
        { text: "ราคา", value: "totalPrice", align: "center" },
        { text: "สถานะการจ่ายเงิน", value: "status", align: "center" },
        { text: "จัดส่งตามที่อยู่", value: "isDelivery", align: "center" },
        { text: "สถานะการจัดส่ง", value: "deliveryStatus", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
        // user: Object
        // activeFlag: (...)
        // createdAt: (...)
        // email: (...)
        // firstName: (...)
        // id: (...)
        // lastName: (...)
        // password: (...)
        // socialId: (...)
        // telCode: (...)
        // telNumber: (...)
        // updatedAt: (...)
        // username: (...)
        ///////
        // createdAt: (...)
        // id: (...)
        // lotteryId: (...)
        // transactionId: (...)
        // updatedAt: (...)
        // count: 1
        // couponCode: null
        // createdAt: "2021-05-10T11:06:09.000Z"
        // deliveryAddress: null
        // id: 93
        // isDelivery: false
        // lotteryDate: null
        // lotteryTransaction: (...)
        // status: "SUCCESS"
        // totalPrice: 240
        // updatedAt: "2021-05-10T11:06:09.000Z"
        // userId: 4
      ],
      items: [],
      search: "",
      count: 0,
      user: "",
    };
  },
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("ReadyLotAdmin"))
    );
    this.getAllTransaction();
  },
  methods: {
    async getAllTransaction() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/findAll`,
        auth
      );
      console.log("getAllTransaction", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    viewTransaction(val) {
      localStorage.setItem("Transactiondata", Encode.encode(val));
      this.$router.push("viewTransaction");
    },
    // UpdateTransaction(val) {
    //   localStorage.setItem("Lottodata", Encode.encode(val));
    //   this.$router.push("EditLotto");
    // },
    async DeleteTransaction(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/transactions/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllLotto();
        }
      });
    },
    goToCreate() {
      this.$router.push("createtype");
    },
  },
};
</script>