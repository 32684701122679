<template>
  <div>
    <ManageLotto />
  </div>
</template>
<script>
import ManageLotto from "@/components/Lotto/ManageLotto";
export default {
  components: {
    ManageLotto,
  },
  created() {},
};
</script>
