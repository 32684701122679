@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h3>ข้อมูลผู้ใช้งาน</h3>
    <v-row class="text-end"> </v-row>
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">ชื่อจริง</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="firstName"
          label="ชื่อจริง"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p class="pt-2">นามสกุล</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="lastName"
          label="นามสกุล"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-end">
      <v-col cols="2"><p class="pt-2">อีเมล</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="email"
          label="อีเมล"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"><p class="pt-2">หมายเลขโทรศัพท์</p></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="telNumber"
          label="หมายเลขโทรศัพท์"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()">แก้ไข</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      telNumber: "",
      activeFlag: true,
      // count: 1
      // createdAt: "2021-05-06T06:51:08.000Z"
      // email: "mail@test.com"
      // firstName: "ชื่อโดนัท"
      // id: 6
      // lastName: "ไอดีไลน์"
      // password: "U8214671e43fce83da803012c917fe908"
      // socialId: "U8214671e43fce83da803012c917fe908"
      // telCode: null
      // telNumber: "0999999999"
      // updatedAt: "2021-05-06T07:10:31.000Z"
      // username: "U8214671e43fce83da803012c917fe908"
    };
  },
  created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log("userdata", userdata);
    this.id = userdata.id;
    this.firstName = userdata.firstName;
    this.lastName = userdata.lastName;
    this.email = userdata.email;
    this.telNumber = userdata.telNumber;
  },
  methods: {
    cancel() {
      this.$router.push("ManageUser");
    },
    async submit() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("ReadyLotAdmin"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const data = {
        id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        telNumber: this.telNumber,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/` + this.id,
        data,
        auth
      );
      console.log("createUser", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขข้อมูลสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("ManageUser");
      } else {
        this.$swal.fire({
          icon: "error",
          text: `แก้ไขข้อมูลไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>