<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>จัดการล็อตเตอรี่</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มประเภทสินค้า</v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersLotto"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewForm3(item)">mdi-eye</v-icon> -->
                <v-icon @click="UpdateLotto(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteLotto(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      headersLotto: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "หมายเลข", value: "lotteryNo", align: "center" },
        { text: "วันที่", value: "lotteryDate", align: "center" },
        { text: "เลขสต็อค", value: "stock", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
        // count: 1
        // createdAt: "2021-04-24T18:16:52.000Z"
        // id: 132
        // lotteryDate: (...)
        // lotteryNo: 338541
        // owner: "admin"
        // price: 80
        // set: 51
        // stock: 0
        // time: 28
        // updatedAt: "2021-05-10T11:06:09.000Z"
        // urlImage: "https://readylotservice.readylotte
      ],
      items: [],
      search: "",
      count: 0,
      user: "",
    };
  },
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("ReadyLotAdmin"))
    );
    this.getAllLotto();
  },
  methods: {
    async getAllLotto() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        id: "",
        lotteryDate: "",
        stock: "",
        owner: "",
      };

      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/lottery/getAll`,
        auth,
        data
      );
      console.log("getAllLotto", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateLotto(val) {
      localStorage.setItem("Lottodata", Encode.encode(val));
      this.$router.push("EditLotto");
    },
    async DeleteLotto(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/types/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllLotto();
        }
      });
    },
    goToCreate() {
      this.$router.push("createtype");
    },
  },
};
</script>